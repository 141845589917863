<template>
<vx-card no-shadow title="Ballot" :customTitle="titleBallotDate" :subtitle="`Status: ${ballot.status} | Capacity: ${ballot.capacity}`">
    <template slot="actions">
        <vs-button v-if="ballot.status==='Open'" class="mr-2" @click="RunBallot" color="success">Run Ballot</vs-button>
        <vs-button v-if="ballot.status==='Close'" class="mr-2" @click="RunBallot" color="warning">Re-Run Ballot</vs-button>
        <vs-button v-if="ballot.status==='Close'" class="mr-2" @click="ApproveBallot" color="success">Approve Ballot</vs-button>
        <vs-button v-if="ballot.status==='Pending'" class="mr-2" @click="OpenBallot" color="success">Open Ballot</vs-button>
        <!-- <vs-button v-if="ballot.status==='Completed'" class="mr-2" @click="CancelBallot" color="danger">Cancel Ballot</vs-button> -->
        <vs-button @click="handleBack" color="dark">Back</vs-button>
    </template>
    <div class="flex mb-base">
        <div class="flex-1">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="ballot.capacity" statisticTitle="Capacity" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="datalist.length" statisticTitle="Invited" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="optedIn" statisticTitle="Opted In" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="optedOut" statisticTitle="Opted Out" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="preference('1')" statisticTitle="Preference 1 (Locals)" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="preference('2')" statisticTitle="Preference 2" />
        </div>
        <div class="flex-1 ml-2">
            <statistics-card-line hideChart icon="CommandIcon" :statistic="preference('Other')" statisticTitle="Others" />
        </div>
    </div>
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <vs-tab icon-pack="feather" icon="icon-check-square" :label="!isSmallerScreen ? 'Ballot Voters' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                <OptIn :currentBallotId="currentBallotId"></OptIn>
            </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-bell" :label="!isSmallerScreen ? 'Actions' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                <Notification :currentBallotId="currentBallotId"></Notification>
            </div>
        </vs-tab>
    </vs-tabs>
</vx-card>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

import {
    ballotData
} from "../../store/api/ballot"

import {
    ballotActionData
} from "../../store/api/ballotAction";

import Notification from "./tabNotification.vue";
import OptIn from "./tabOptIn.vue";
import moment from 'moment'

export default {
    data() {
        return {
            currentBallotId: "",
            ballot: {},
            datalist: [],
        };
    },
    components: {
        OptIn,
        Notification,
        StatisticsCardLine
    },
    computed: {
        titleBallotDate() {
            return moment(String(this.ballot.ballotDate)).format('ddd, DD MMM yy');
        },
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768;
        },
        ballotID() {
            return this.$route.params.id;
        },
        optedOut() {
            return this.datalist.filter(d => d.status === 'OptOut').length;
        },
        optedIn() {
            return this.datalist.filter(d => d.status === 'OptedIn').length;
        }
    },
    async created() {
        this.currentBallotId = this.ballotID;
        this.getBallot();
        await this.loadBallot();
        await this.loadOptIns();
    },
    methods: {
        async loadBallot() {
            this.ballot = await ballotData.SearchBallotByKey(this.ballotID);
        },
        async loadOptIns() {
            this.datalist = await ballotData.searchAllBallotOptIns(this.ballotID);
        },
        preference(number) {
            return this.datalist.filter(d => d.preference === number && (d.status === 'Selected' || d.status === 'Success')).length;
        },
        async getBallot() {
            if (this.ballotID) {
                this.ballot = await ballotData.SearchBallotByKey(this.ballotID)
                if (this.ballot) {
                    this.currentBallotId = this.ballot.id;
                } else {
                    this.$router.back();
                }
            }
        },
        updateEmployeeId(id) {
            this.currentBallotId = id;
        },
        handleBack() {
            this.$router.back();
        },
        async OpenBallot() {
            let result = await ballotActionData.openBallot({
                id: this.currentBallotId
            });

            if (result.succeeded) {
                this.$vs.notify({
                    title: "Success",
                    color: "success",
                    position: "top-center"
                });
                this.loadBallot();
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-center"
            });
        },
        async RunBallot() {

            if (!confirm("Are you sure ?")) {
                return;
            }

            let result = await ballotActionData.runBallot({
                id: this.currentBallotId
            });

            if (result.succeeded) {
                this.$vs.notify({
                    title: "Success",
                    color: "success",
                    position: "top-center"
                });
                this.loadBallot();
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-center"
            });
        },
        async ApproveBallot() {

            if (!confirm("Are you sure ?")) {
                return;
            }

            let result = await ballotActionData.approveBallot({
                id: this.currentBallotId
            });

            if (result.succeeded) {
                this.$vs.notify({
                    title: "Success",
                    color: "success",
                    position: "top-center"
                });
                this.loadBallot();
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-center"
            });
        },
        async CancelBallot() {

            if (!confirm("Are you sure ?")) {
                return;
            }

            let result = await ballotActionData.approveBallot({
                id: this.currentBallotId
            });

            if (result.succeeded) {
                this.$vs.notify({
                    title: "Success",
                    color: "success",
                    position: "top-center"
                });
                this.loadBallot();
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-center"
            });
        },
    }
};
</script>
