<template>
<vx-card no-shadow card-border title="Ballot Voters">
    <template slot="actions">
        <div class="flex">
            <vs-select name="status" v-model="selectedStatus" @change="loadOptIns" class="w-1/2 mr-5">
                <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in selectStatus" />
            </vs-select>
            <vs-input v-model="searchString" class="mr-2" @keyup="loadOptIns" placeholder="Search"></vs-input>
            <vs-button class="ml-2" color="success" @click="downloadexcel">Export</vs-button>
        </div>
    </template>
    <vs-table stripe :data="datalist">
        <template #thead>
            <vs-th>Name</vs-th>
            <vs-th>Registration</vs-th>
            <vs-th>Post Code</vs-th>
            <vs-th>Date#</vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in datalist" :key="index" :data="tr">
                <vs-td>{{tr.displayName}}</vs-td>
                <vs-td>{{tr.registrationPlate}}</vs-td>
                <vs-td>{{tr.userHomePostcode}}</vs-td>
                <vs-td>{{tr.lastActionDate | formatDate }}</vs-td>
                <vs-td>
                    <vs-button v-if="tr.status==='Failed'" color="success">Approve</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</vx-card>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    data() {
        return {
            currentAction: null,
            content: `...`,
            editorOption: {},
            datalist: [],
            currentBallot: {
                capacity: 0
            },
            searchString: "",
            selectedStatus: "OptedIn",
            selectStatus: [
                "Invited",
                "OptedIn",
                "OptOut",
                "Selected",
                "Success",
                "Failed"
            ],
        }
    },
    computed: {
        optedOut() {
            return this.datalist.filter(d => d.status === 'OptOut').length;
        },
        optedIn() {
            return this.datalist.filter(d => d.status === 'OptedIn').length;
        }
    },
    components: {
        StatisticsCardLine
    },
    props: {
        currentBallotId: {
            type: String,
            require: true
        }
    },
    async created() {
        await this.loadBallot();
        await this.loadOptIns();
    },
    methods: {
        async loadBallot() {
            this.currentBallot = await ballotData.SearchBallotByKey(this.currentBallotId);
        },
        async loadOptIns() {
            this.datalist = await ballotData.searchBallotOptIns(this.currentBallotId, this.selectedStatus, this.searchString);
        },
        preference(number) {
            return this.datalist.filter(d => d.preference === number && d.status === 'Selected').length;
        },
        async downloadexcel() {
            const reportParameter = {
                id: this.currentBallotId
            };

            let data = await ballotData.ballotRequestExportToExcel(reportParameter);
            window.open(data.url, '_blank').focus();
        },
    }
};
</script>
