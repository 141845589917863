import { axiosInstance } from "../../axios";

const GETSearchOpenBallotNotification = `/api/Notification/SearchOpenBallotNotification`;
const GETSearchOpenFCFSNotification = `/api/Notification/SearchFCFSNotification`;
const GETSearchOpenCloseBallotNotification = `/api/Notification/SearchCloseBallotNotification`;
const GETSearchBallotFullNotification = `/api/Notification/SearchBallotFullNotification`;

const searchOpenBallotNotification = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchOpenBallotNotification}/${id}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return null;
  }
};

const searchOpenFCFSNotification = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchOpenFCFSNotification}/${id}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return null;
  }
};

const searchCloseBallotNotification = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchOpenCloseBallotNotification}/${id}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return null;
  }
};

const searchBallotFullNotification = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchBallotFullNotification}/${id}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return null;
  }
};

const parseList = response => {
  if (response.status === 204) throw Error("Please Select Company");
  if (response.status !== 200) throw Error(response.message);

  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const notificationData = {
  searchOpenBallotNotification,  
  searchOpenFCFSNotification,
  searchCloseBallotNotification,
  searchBallotFullNotification
};
