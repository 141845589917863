<template>
<div>
    <h5 class="mt-4 text-secondary mb-base">Notifications & Actions</h5>
    <vs-table stripe hoverFlat :data="ballotActions">
        <template #thead>
            <vs-th class="width1"></vs-th>
            <vs-th>Action</vs-th>
            <vs-th>Result</vs-th>
            <vs-th class="width10">Status</vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in ballotActions" :key="index" :data="tr">
                <vs-td>
                    <vs-button :disabled="tr.start" @click="takeAction(tr.actionName, tr.ballotId)" v-if="tr.status === 'Pending'">Start</vs-button>
                </vs-td>
                <vs-td @click="viewAction">{{tr.displayDescription}}</vs-td>
                <vs-td>{{tr.result }}</vs-td>
                <vs-td>
                    <vs-chip v-if="tr.status==='Started'" color="success">{{tr.status}}</vs-chip>
                    <vs-chip v-if="tr.status==='NotStarted'" color="warning">Not Started</vs-chip>
                    <vs-chip v-if="tr.status==='Completed'" color="success">{{tr.status}}</vs-chip>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</div>
</template>

<script>
import {
    notificationData
} from "../../store/api/notification";

import {
    ballotActionData
} from "../../store/api/ballotAction";

import {
    ballotData
} from "../../store/api/ballot";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'

export default {
    data() {
        return {
            currentAction: null,
            content: `...`,
            editorOption: {},
            ballotActions: [],
            currentBallot: null
        }
    },
    props: {
        currentBallotId: {
            type: String,
            require: true
        }
    },
    components: {
        quillEditor,
    },
    async created() {
        this.loadBallot();
        this.loadBallotActions();
    },
    methods: {
        async loadBallotActions() {
            this.ballotActions = await ballotActionData.searchBallotAction(this.currentBallotId);
        },
        viewAction() {},
        async loadBallot() {
            this.currentBallot = await ballotData.SearchBallotByKey(this.currentBallotId)
        },
        async takeAction(action, ballotId) {
            let result = {};
            if (action === 'OpenBallot') {
                result = await ballotActionData.openBallot({
                    id: ballotId
                });

            } else if (action === 'RunBallot') {
                result = await ballotActionData.runBallot({
                    id: ballotId
                });
            } else if (action === 'ApproveBallot') {
                result = await ballotActionData.approveBallot({
                    id: ballotId
                });
            }

            if (result.succeeded) {
                this.$vs.notify({
                    title: "Success",
                    color: "success",
                    position: "top-center"
                });
                this.loadBallot();
                this.loadBallotActions();
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-center"
            });
        },
        async getNotification(notification) {
            this.content = null;
            if (notification === 'openBallot') {
                const data = await notificationData.searchOpenBallotNotification(this.currentBallotId);
                if (data) {
                    this.content = data.notification;
                }
            } else if (notification === 'firstcomefirstserve') {
                const data = await notificationData.searchOpenFCFSNotification(this.currentBallotId);
                if (data) {
                    this.content = data.notification;
                }
            } else if (notification === 'fullballot') {
                const data = await notificationData.searchBallotFullNotification(this.currentBallotId);
                if (data) {
                    this.content = data.notification;
                }
            } else if (notification === 'closeballot') {
                const data = await notificationData.searchCloseBallotNotification(this.currentBallotId);
                if (data) {
                    this.content = data.notification;
                }
            }

        },

        async cancelBallot() {
            if (confirm("are you sure ? ")) {
                await this.loadBallotActions();
            }
        }
    }
};
</script>
